import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import supabase from './Supabase';
import { useAuth } from './AuthContext';
import { throttle } from 'lodash'; // Import lodash throttle to manage save operations

export interface Preferences {
  serving_size: string | null;
  priority_items: string[];
  duration_availability: string | null;
  avoid_items: string[];
  health_conditions: string[];
}

interface PreferencesContextType {
  preferences: Preferences;
  setPreferences: (newPreferences: Preferences) => void;
  savePreferences: () => Promise<void>;
  loadPreferences: () => Promise<void>;
  migratePreferencesToUser: () => Promise<void>;
}

const defaultPreferences: Preferences = {
  serving_size: '1 serving',
  priority_items: [],
  duration_availability: '30 minutes or less',
  avoid_items: [],
  health_conditions: [],
};

const defaultPreferencesContext: PreferencesContextType = {
  preferences: defaultPreferences,
  setPreferences: () => {},
  savePreferences: async () => {},
  loadPreferences: async () => {},
  migratePreferencesToUser: async () => {},
};

const PreferencesContext = createContext<PreferencesContextType>(defaultPreferencesContext);

export const PreferencesProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();
  const [preferences, setPreferencesState] = useState<Preferences>(defaultPreferences);

  useEffect(() => {
    const saveRecipe = async () => {
      if (!user) return;
      const recipe_id = localStorage.getItem('recipe_to_save');
      await supabase
        .from('saved_recipe')
        .insert({
          user_id: user.id,
          recipe_id: recipe_id,
        });
      localStorage.removeItem('recipe_to_save');
    };

    saveRecipe();
  }, [user]);

  const setPreferences = (newPreferences: Preferences) => {
    setPreferencesState(newPreferences);
    throttledSavePreferences(newPreferences); // Save whenever preferences change
  };

  // Throttle the save operation to reduce backend load and avoid rapid updates
  const throttledSavePreferences = throttle(async (updatedPreferences: Preferences) => {
    try {
      if (user) {
        const { data: existingPreferences, error: fetchError } = await supabase
          .from('preferences')
          .select('id')
          .eq('user_id', user.id)
          .single();

        if (fetchError) {
          console.error('Error fetching existing preferences:', fetchError);
          return;
        }

        if (existingPreferences) {
          const { error: updateError } = await supabase
            .from('preferences')
            .update({ preferences: updatedPreferences })
            .eq('user_id', user.id);

          if (updateError) {
            console.error('Error updating preferences in the backend:', updateError);
          }
        } else {
          const { error: insertError } = await supabase
            .from('preferences')
            .insert([{ user_id: user.id, preferences: updatedPreferences }]);

          if (insertError) {
            console.error('Error saving new preferences to the backend:', insertError);
          }
        }
      } else {
        localStorage.setItem('preferences', JSON.stringify(updatedPreferences));
      }
    } catch (error) {
      console.error('Error saving preferences:', error);
    }
  }, 1000); // Throttle interval of 1000ms (1 second)

  const savePreferences = async () => {
    await throttledSavePreferences(preferences);
  };

  const loadPreferences = async () => {
    try {
      if (user) {
        const { data, error } = await supabase
          .from('preferences')
          .select('preferences')
          .eq('user_id', user.id)
          .single();

        if (error) {
          console.error('Error fetching preferences from backend:', error);
          setPreferences(defaultPreferences);
        } else if (data && data.preferences) {
          setPreferences(data.preferences as Preferences);
        } else {
          setPreferences(defaultPreferences);
        }
      } else {
        const storedPreferences = localStorage.getItem('preferences');
        if (storedPreferences) {
          try {
            const parsedPreferences = JSON.parse(storedPreferences);
            setPreferences(parsedPreferences);
          } catch (error) {
            console.error('Error parsing preferences from local storage:', error);
            setPreferences(defaultPreferences);
          }
        } else {
          setPreferences(defaultPreferences);
        }
      }
    } catch (error) {
      console.error('Error loading preferences:', error);
      setPreferences(defaultPreferences);
    }
  };

  const migratePreferencesToUser = async () => {
    try {
      if (!user) return;

      const storedPreferences = localStorage.getItem('preferences');
      if (!storedPreferences) return;

      const preferencesToMigrate: Preferences = JSON.parse(storedPreferences);
      const { data: existingPreferences, error: fetchError } = await supabase
        .from('preferences')
        .select('id')
        .eq('user_id', user.id)
        .single();

      if (fetchError) {
        console.error('Error checking for existing preferences:', fetchError);
        return;
      }

      if (existingPreferences) {
        const { error: updateError } = await supabase
          .from('preferences')
          .update({ preferences: preferencesToMigrate })
          .eq('user_id', user.id);

        if (updateError) {
          console.error('Error migrating preferences to the backend:', updateError);
        } else {
          localStorage.removeItem('preferences');
        }
      } else {
        const { error: insertError } = await supabase
          .from('preferences')
          .insert([{ user_id: user.id, preferences: preferencesToMigrate }]);

        if (insertError) {
          console.error('Error migrating preferences to the backend:', insertError);
        } else {
          localStorage.removeItem('preferences');
        }
      }
    } catch (error) {
      console.error('Error migrating preferences to the backend:', error);
    }
  };

  useEffect(() => {
    const handlePreferences = async () => {
      if (user) {
        await migratePreferencesToUser();
      }
      await loadPreferences();
    };

    handlePreferences();
  }, [user]);

  // Sync state across tabs
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'preferences') {
        const updatedPreferences = JSON.parse(event.newValue || '{}');
        setPreferences(updatedPreferences);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <PreferencesContext.Provider value={{ preferences, setPreferences, savePreferences, loadPreferences, migratePreferencesToUser }}>
      {children}
    </PreferencesContext.Provider>
  );
};

export const usePreferences = () => useContext(PreferencesContext);
