import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab, Drawer } from '@mui/material';
import { useLocation } from 'react-router-dom';
import LetsGo from './LetsGo';
import PreferencesComponent from './Preferences';
import './RecipeList.css';
import BottomBar from './BottomBar';
import Signup from './Signup';
import Loading from './Loading';
import NeedsLogin from './NeedsLogin';
import { useAuth } from './AuthContext';
import supabase from './Supabase';
import { Preferences, usePreferences } from './PreferencesContext';
import RecipeDetails from './RecipeDetails';
import Profile from './Profile';
import RecipeListItem from './RecipeListItem';
import Cookbook from './Cookbook';

export interface NutritionFacts {
  servingSize: string;
  calories: number;
  totalCarbohydrates: number;
  fiber: number;
  sugar: number;
  protein: number;
  totalFat: number;
  saturatedFat: number;
  transFat: number;
  cholesterol: number;
  sodium: number;
  potassium: number;
}

export interface Recipe {
  id: number;
  createdAt?: string;
  title: string;
  quickHealthyDescription: string;
  longHealthyDescription: string;
  keyIngredients: string[];
  fullIngredients: { name: string; amount: string; detail: string }[];
  instructions: string[];
  nutritionFacts: NutritionFacts;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const fetchRecipes = async (
  user: any,
  preferences: any,
  recipes: Recipe[],
  setRecipes: React.Dispatch<React.SetStateAction<Recipe[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  controller: AbortController,
) => {
  setLoading(true);
  setError('');

  // Fetch preferences from context
  const servingSize = preferences.serving_size || '1 serving';
  const priorityItems = preferences.priority_items || [];
  const durationAvailability = preferences.duration_availability || '30 minutes or less';
  const avoidItems = preferences.avoid_items || [];
  const healthConditions = preferences.health_conditions || [];

  // Conditionally construct the "differentiate" part of the query
  const differentiatePart = recipes && recipes.length
    ? `Differentiate from these existing recipes: ${recipes.map((recipe) => recipe.title).join(', ')}`
    : '';

  // Generate the query using preferences from context
  const query = `Generate recipes based on the following preferences:
    Serving Size: ${servingSize},
    Priority Items: ${priorityItems.join(', ')},
    Duration Availability: ${durationAvailability},
    Avoid Items: ${avoidItems.join(', ')},
    Health Conditions: ${healthConditions.join(', ')}
    ${differentiatePart}
  `.trim();

  const requestBody = {
    query: query,
  };

  const maxRetries = 3;
  let attempts = 0;

  while (attempts < maxRetries) {
    try {
      const response = await fetch('https://us-east1-yoridachi.cloudfunctions.net/generateRecipes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
        signal: controller.signal,
      });

      const data = await response.json();
      const newRecipes = JSON.parse(data.choices[0].message.content).recipes;
      const updatedRecipes = await Promise.all(
        newRecipes.map(async (recipe: any) => {
          const { data: insertedData, error } = await supabase
            .from('recipes')
            .insert({
              ...(user ? { user_id: user.id } : {}),
              recipe,
              query,
            })
            .select();
          if (error) {
            return recipe;
          }

          return { ...recipe, id: insertedData[0].id };
        })
      );
      setRecipes(updatedRecipes);
      break;
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        break;
      }
      attempts += 1;
      if (attempts >= maxRetries) {
        setError('Error fetching recipes. Please try again.');
      }
    }
  }

  setLoading(false);
};

const RecipeList: React.FC = () => {
  const hasPerformedInitialFetch = useRef(false);
  const params = new URLSearchParams(window.location.search);
  const generateOnLoad = params.get('g') === '1';
  const recipeCollection = params.get('collection');
  const abortControllerRef = useRef(new AbortController());
  const [loading, setLoading] = useState(false);
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [error, setError] = useState('');
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);

  const [cookbookTabIndex, setCookbookTabIndex] = useState(0);
  const [navigationTabIndex, setNavigationTabIndex] = useState(0);

  const [signup, setSignup] = useState(0);
  const [needsLoginModal, setNeedsLoginModal] = useState(false);
  const { user, firstName } = useAuth();

  const [preferences, setPreferences] = useState(false);
  const { preferences: preferencesFromUser } = usePreferences();

  useEffect(() => {
    if (generateOnLoad && !hasPerformedInitialFetch.current) {
      const controller = new AbortController();
      abortControllerRef.current = controller;

      fetchRecipes(user, preferencesFromUser, recipes, setRecipes, setLoading, setError, controller);
      hasPerformedInitialFetch.current = true;
    }
  }, []);

  const handleRecipeClick = (recipe: Recipe) => {
    setSelectedRecipe(recipe);
  };

  const handleGenerateMore = async () => {
    abortControllerRef.current = new AbortController();
    fetchRecipes(user, preferencesFromUser, recipes, setRecipes, setLoading, setError, abortControllerRef.current);
  };

  const handleGenerateFromPreferences = async (newPreferences: Preferences) => {
    abortControllerRef.current = new AbortController();
    fetchRecipes(user, newPreferences, recipes, setRecipes, setLoading, setError, abortControllerRef.current);
  };

  const handleNavigationChange = (index: number) => {
    if ((index === 1 || index === 2) && !user) {
      setNeedsLoginModal(true);
      return;
    }

    setNavigationTabIndex(index);
  };

  if (!user && navigationTabIndex !== 0) {
    setNavigationTabIndex(0);
  }

  useEffect(() => {
    if (recipeCollection) {
      setLoading(true);

      (async () => {
        try {
          const { data: collectionData, error: collectionError } = await supabase
            .from('recipe_collection')
            .select('recipes')
            .eq('name', recipeCollection)
            .single();

          if (collectionError) {
            setError('Error loading recipe collection.');
            setLoading(false);
            return;
          }

          const recipeIds = collectionData.recipes;
          const { data: recipesData, error: recipesError } = await supabase
            .from('recipes')
            .select('*')
            .in('id', recipeIds);


          if (recipesError) {
            setError('Error loading recipes.');
            setLoading(false);
            return;
          }

          setRecipes(recipesData.map((recipe: any, idx: number) => {
            return { ...recipe.recipe, id: idx }
          }));
        } catch (error) {
          setError('Error loading recipe collection.');
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [recipeCollection]);

  const needsLoginDrawer = (
    <Drawer
      anchor={'bottom'}
      open={needsLoginModal}
      onClose={() => setNeedsLoginModal(false)}
    >
      <NeedsLogin
        onSignupClick={() => { setNeedsLoginModal(false); setSignup(1); }}
        onLoginClick={() => { setNeedsLoginModal(false); setSignup(2); }}
        onDismissClick={() => setNeedsLoginModal(false)}
      />
    </Drawer>
  );

  window.scrollTo(0, 0);
  if (signup) {
    return (
      <Signup onClose={() => { setSignup(0); setRecipes([]); }} needsLoginState={signup === 2} />
    );
  } else if (preferences) {
    return (
      <PreferencesComponent
        handleSave={(newPreferences) => { setPreferences(false); handleGenerateFromPreferences(newPreferences); }}
        handleCancel={() => setPreferences(false)}
      />
    );
  } else if (loading) {
    return (
      <Loading onCancel={() => abortControllerRef.current.abort()} />
    );
  }

  const bottomBar = (
    <>
      {needsLoginDrawer}
      <BottomBar selectedIndex={navigationTabIndex} onClick={(index) => handleNavigationChange(index)} />
    </>
  );

  if (selectedRecipe) {
    return (
      <RecipeDetails
        recipe={selectedRecipe}
        onClose={() => { setSelectedRecipe(null); }}
        setError={setError}
        setSignup={setSignup}
      />
    );
  }

  if (navigationTabIndex === 1) {
    return <Cookbook tabIndex={cookbookTabIndex} setTabIndex={setCookbookTabIndex} children={bottomBar} />
  } else if (navigationTabIndex === 2) {
    return <Profile children={bottomBar} />
  }

  if (recipes.length === 0) {
    return (
      <LetsGo
        onClick={() => setPreferences(true)}
        onSignupClick={() => setSignup(1)}
        children={bottomBar}
      />
    );
  }

  return (
    <div className='recipe-list-container'>
      <div className='recipe-list-header'>
        {!firstName && <span>Recipes for you</span>}
        {firstName && <span>{`Recipes for you, ${firstName}`}</span>}
        {!user && <div className='recipe-list-signup' onClick={() => setSignup(1)}>Sign up</div>}
      </div>
      <div className='recipe-list-content'>
        {recipes.map((recipe, index) => <RecipeListItem
          key={recipe.id}
          recipe={recipe}
          recipeIndex={index}
          onRecipeClick={handleRecipeClick}
        />)}
        <button className="recipe-list-generate-more" onClick={handleGenerateMore}>
          Generate three additional recipes
        </button>
        <img onClick={() => { setCookbookTabIndex(1); setNavigationTabIndex(1); }} className="recipe-list-tip" src={`${process.env.PUBLIC_URL}/recipe-list-tip.png`} alt="Logo" />
      </div>
      {error && (
        <div className='recipe-list-error'>
          {error}
        </div>
      )}
      <button className='recipe-list-edit-preferences' onClick={() => (setPreferences(true))}>
        Edit Preferences
      </button>
      {bottomBar}
    </div>
  );
};

export default RecipeList;
